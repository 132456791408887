import './Products.css';

function Products() {
  return (
    <div className="Products">
      <h1>Products</h1>
    </div>
  );
}

export default Products;
