import './App.css';
import React from 'react';
import About from './components/About';
import Home from './components/Home';
import Products from './components/Products';
import { BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';

function App() {
  return (
    <Router>
      <nav style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Link to="/about">About Us</Link>
        <Link to="/products">Products</Link>
      </nav>

      <Routes>
        <Route path="/about" element={<About/>}/>
        <Route path="/products" element={<Products/>}/>
      </Routes>
    </Router>
  );
}

export default App;
